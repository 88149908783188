import React from 'react';
import './css/Footer.css';

const Footer = () => {
  
  return (
      <div id='footer'>
          <p>Camhire VENDOUT INDIA PRIVATE LIMITED</p>
      </div>
  )
}

export default Footer
